<template>
    <div class="container" :key="userLang">
        <!-- :key="$root.language.language.code" -->
        <w-header :nav="headerNav"></w-header>

        <transition name="fade" mode="out-in">
            <router-view :key="animationKey"></router-view>
        </transition>

        <w-cookies-popup></w-cookies-popup>

        <w-footer :nav="footerNav"></w-footer>
    </div>
</template>

<script>
import 'truefuture-ui/src/assets/main.less'
import empty from './empty.vue'

export default {
    components: {
        'empty': empty
    },
    computed: {
        animationKey() {
            try {
                if (this.$route.name.includes('collection')) {
                    return 'collection'
                } else if (this.$route.name.includes('market')) {
                    return this.$route.fullPath
                } else if (this.$route.fullPath.includes('app/assets')) {
                    return 'assets'
                }
                else {
                    return this.$route.fullPath
                }
            } catch {
                return this.$route.fullPath
            }
        },
        userLang() {
            try {
                if (this.$i18n.language.code && this.$store.state.user.language) {
                    return this.$i18n.language.code + this.$store.state.user.language
                }
            } catch {
                return 'auto'
            }
        }
    },
    data() {
        return {
            headerNav: [ /*{
              routeName: 'true-nto',
              isActive: ['true-nto'],
              title: 'Loyalty NTO'
            }, */{
                routeName: 'statuses',
                isActive: ['statuses'],
                title: 'Status cards'
            }, {
                routeName: 'market',
                isActive: ['market', 'item', 'nfts', 'nfts-all', 'merchants', 'nft-item', 'collections', 'search', 'merchant', 'collection'],
                title: 'Marketplace'
            }, {
              routeName: 'earn',
              isActive: ['earn'],
              title: 'Earn'
            }, /*{
              title: 'About token',
              link: 'https://tft.club'
            }, *//*{
                routeName: 'game-world',
                isActive: ['game-world', 'for-developers'],
                title: 'Game Worlds'
            }, *//*{
                routeName: 'community',
                isActive: ['community', 'news'],
                title: 'Community'
            }, *//*{
                routeName: 'earn',
                isActive: ['earn', 'staking', 'swap'],
                title: 'Earn'
            }, */{
                title: 'For business',
                isActive: false,
                subNav: [{
                    title: 'About',
                    link: 'https://business.truefuture.io/about'
                }, {
                    title: 'Products',
                    link: 'https://business.truefuture.io/products'
                }, {
                    title: 'Solutions',
                    link: 'https://business.truefuture.io/solutions'
                }]
            },],
            footerNav: [{
                routeName: 'cookies-policy',
                isActive: ['cookies-policy'],
                title: 'Cookies Policy'
            }, {
                routeName: 'privacy-policy',
                isActive: ['privacy-policy'],
                title: 'Privacy Policy'
            }, {
                routeName: 'terms',
                isActive: ['terms'],
                title: 'Terms and Conditions'
            }, {
                routeName: 'contact-us',
                isActive: ['contact-us'],
                title: 'Contact Us'
            }, /*{
                link: 'https://whitepaper.truefuture.io/',
                title: 'White Paper'
            }*/]
        }
    },
    mounted() {
        if (process.env.NODE_ENV === 'production') {
            const gtmScript = document.createElement('script')
            gtmScript.setAttribute('async', '')
            gtmScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-706XSJRNF3')

            document.head.appendChild(gtmScript)

            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-706XSJRNF3');
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > div {
        flex-grow: 1;
    }

    > .header {
        flex-grow: 0;
    }

    > .footer {
        flex-grow: 0;
    }
}
</style>
