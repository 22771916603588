import Vue from 'vue'
import Modal from 'vue-js-modal'
import InfiniteScroll from 'vue-infinite-scroll'
import Draggable from 'vuedraggable'
import VueShowdown from 'vue-showdown'
import VueEasyLightbox from 'vue-easy-lightbox'
import vClickOutside from 'v-click-outside'
import VueAwesomeCountdown from 'vue-awesome-countdown/src/vue-awesome-countdown'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import VueCircle from 'vue2-circle-progress'
import MultiRangeSlider from 'multi-range-slider-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

Vue.use(Modal, {
    dynamicDefaults: {
        shiftY: 0,
        adaptive: true,
        draggable: false,
        resizable: false,
        height: '100%',
        transition: 'modal'
    }
})
Vue.use(VueShowdown, {
    flavor: 'github',
    options: {
        emoji: false,
    }
})
Vue.use(InfiniteScroll)
Vue.use(Draggable)
Vue.use(VueEasyLightbox)
Vue.use(vClickOutside)
Vue.use(VueAwesomeCountdown)

Vue.component('vue-circle', VueCircle)
Vue.component('date-picker', DatePicker)
Vue.component('multi-range-slider', MultiRangeSlider)
Vue.component('swiper', Swiper)
Vue.component('swiper-slide', SwiperSlide)

import Helpers from './Helpers'
import Components from './Components'

Vue.use({
    install: Helpers
})

Vue.use({
    install: Components
})
