import Vue from 'vue'

process.client = true

// Plugins подключает все плагины. Его необходимо импортить, чтобы он тупо завелся
import Plugins from './plugins/index'

import Router from './plugins/Routes'
import Store from './plugins/Store'
import Mixins from './plugins/Mixins'

import App from './App'
const AppMixin = Mixins.App

new Vue({
    el: '#app',
    router: Router,
    store: Store,
    render: h => h(App),
    mixins: [AppMixin]
})
