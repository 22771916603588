import Vuex from 'vuex'
import Vue from 'vue'
import { Store as UiStore } from 'truefuture-ui'

Vue.use(Vuex)

const Store = new Vuex.Store({
    state() {
        return {
            ...UiStore.state
        }
    },
    getters: {
        ...UiStore.getters
    },
    actions: {
        ...UiStore.actions
    },
    mutations: {
        ...UiStore.mutations
    },
    modules: {
        ...UiStore.modules
    }
})

export default Store